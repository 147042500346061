<template>
  <div>
    <div class="head">
      <div class="header">
          <div class="logo">
            <router-link to="/home"><img src="@/assets/img/logo.png" alt="大医精方官网"></router-link>
          </div>
          <el-button v-if="isMobile" type="text" @click="onDrawer"><i class="el-icon-s-fold" style="font-size:25px;margin-right:15px"></i></el-button>
          <div class="nav" v-if="!isMobile">
            <ul>
              <li v-for="(item,index) in menu" :key="'menu'+index" :class="{active:$route.path==item.path}">
                <router-link :to="item.path">{{item.name}}</router-link>
              </li>
            </ul>
          </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :before-close="handleClose"
      size="50%">
      <div class="drawer">
        <ul>
          <li v-for="(item,index) in menu" :key="'menu'+index" :class="{active:$route.path==item.path}" @click="drawer=false;">
            <router-link :to="item.path">{{item.name}}</router-link>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile:false,
      drawer:false,
      menu:[
        {path:'/',   name:'首页'},
        {path:'/blurb',  name:'公司简介'},
        {path:'/brand',  name:'品牌项目'},
        {path:'/regimen',name:'养生知识'},
        {path:'/Gongfa', name:'功法视频'},
        {path:'/honor',  name:'资质荣誉'},
        {path:'/Contact',name:'联系我们'},
      ]
    };
  },
  mounted(){
    if (window.screen.width < 500) {
      //移动端
      this.isMobile = true;
    }else{
      //pc
      this.isMobile = false;
    }
  },
  methods:{
    onDrawer(){
      this.drawer = true;
    },
    handleCommand(path){
      this.$router.push(path);
    },
    handleClose(){
      this.drawer = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.head{
  width: 100%;
  position:fixed;
	top:0 ;
	z-index:5;
  height: 100px;
	background:#ffd700;
  .header{
    max-width: 1200px;
    height: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      width: 220px;
      img {
        display: block;
        width: auto;
        max-width: 90%;
      }
    }
    .nav {
      width: 700px;
      ul {
        width: 100%;
        display: flex;
        li {
          position: relative;
          float: left;
          height: 60px;
          width: 140px;
          padding-bottom: 2px;
          a {
            display: block;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 18px;
            color: #333333;
            &:hover a {
              color: #3aacad;
              margin-bottom: -2px;
            }
          }
        }
      }
    }
  }
}
.nav ul .active a {
	color: #d7141c !important;
	margin-bottom: -2px;
}
.drawer{
  display: flex;
  justify-content: center;
  li{
    text-align: center;
    width: 150px;
    line-height: 40px;
    font-size: 16px;
    border-bottom: 1px solid #f0f0f0;
  }
  .active{
    a{
      color: #ffd700 !important;
    }
  }
}
</style>