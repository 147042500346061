<template>
  <div>
    <div class="leader">
      <div class="wrapper">
        <span>当前位置：</span>
        <!-- <a href="https://www.gxmu.edu.cn/" title="首页">首页</a>> -->
        <span class="toHome" title="医大新闻网" @click="toHome">医大新闻网</span
        >>
        <span
          class="toName"
          @click="toName1"
          :title="$route.query.name"
          v-if="$route.query.name"
          >{{ $route.query.name }}</span
        >
        <span
          v-else-if="nowName === '新闻头条'"
          tittle="新闻头条"
          class="toName"
          @click="toName2"
          >{{ "新闻头条" }}</span
        >
        <span v-else :tittle="nowName" class="toName" @click="toName2">{{
          nowName
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      id: "",
    };
  },
  props: ["nowName", "categoryId"],
  methods: {
    toHome() {
      this.$router.push({
        path: "/home",
      });
    },
    // 如果在分类页面
    //刷新当前页面
    toName1() {
      this.$router.go(0);
    },
    // 如果在文章页面
    toName2() {
      if (this.nowName === "新闻头条") {
        this.$router.push({
          path: "/category",
          query: {
            id: 4,
            name: "学校要闻",
          },
        });
      } else {
        this.$router.push({
          path: "/category",
          query: {
            id: this.categoryId,
            name: this.nowName,
          },
        });
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.name = this.$route.query.name;
  },
};
</script>

<style lang="scss" scoped>
.leader {
  margin-top: 15px;
  .wrapper {
    height: 40px;
    border-bottom: 1px solid #ebebeb;
    line-height: 40px;
    a:hover {
      color: #b30000;
    }
    .toHome:hover {
      color: #b30000;
      cursor: pointer;
    }
    .toName:hover {
      color: #b30000;
      cursor: pointer;
    }
  }
}
</style>