<template>
  <div>
    <div class="news_nav">
      <div class="wrapper">
        <ul>
          <li @click="toOne"><a href="#">首页</a></li>
          <li @click="toTwo"><a href="#">学校要闻</a></li>
          <li @click="toThree"><a href="#">基层新闻</a></li>
          <li @click="toFour"><a href="#">媒体医大</a></li>
          <li @click="toFive"><a href="#">附属医院</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toOne() {
      this.$router.push({
        path: "/home",
      });
    },
    toTwo() {
      this.$router.push({
        path: "/category",
        query: {
          id: 4,
          name: "学校要闻",
        },
      });
    },
    toThree() {
      this.$router.push({
        path: "/category",
        query: {
          id: 6,
          name: "基层动态",
        },
      });
    },
    toFour() {
      this.$router.push({
        path: "/category",
        query: {
          id: 5,
          name: "媒体医大",
        },
      });
    },
    toFive() {
      this.$router.push({
        path: "/category",
        query: {
          id: 7,
          name: "附属医院",
        },
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
// 导航栏
.news_nav {
  width: 100%;
  height: 60px;
  .wrapper {
    height: 100%;
    border-bottom: 1px solid #be5b24;
    ul {
      li {
        float: left;
        margin-right: 20px;
        a {
          display: inline-block;
          height: 58px;
          padding: 0 20px;
          font-size: 18px;
          line-height: 60px;
          color: #333;
          font-family: "Microsoft Yahei", "sans-serif";
        }
        a:hover {
          border-bottom: 2px solid #be5b24;
        }
      }
    }
  }
}
</style>