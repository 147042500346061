<template>
  <div>
    <div class="side">
        <ul>
            <li> 
                <a>
                    <div class="sidebox"><img src="@/assets/img/side_icon01.png">QQ在线咨询</div>
                </a>
            </li>
            <li>
                <a>
                    <div class="sidebox"><img src="@/assets/img/side_icon03.png">400-123-4567</div>
                </a> 
            </li>
        </ul>
    </div>
    <div class="side2 ">
        <ul>
            <li> 
                <a><img src="@/assets/img/r_icon1.png" alt=""></a>
                <div class="weixin"><em></em><img src="@/assets/img/ewm.jpg" alt=""></div>
            </li>
            <li><a href="javascript:goTop(); " class="sidetop "><img src="@/assets/img/r_icon2.png"></a></li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>