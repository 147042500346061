import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/styles.scss'

import '@/assets/css/index.css'

import * as filters from './filters'
// 全局注册头部和底部
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'
import Leader from '@/components/Leader.vue'
import Side from '@/components/side.vue'

Vue.component('Header', Header)
Vue.component('Footer', Footer)
Vue.component('Nav', Nav)
Vue.component('Leader', Leader)
Vue.component('Side', Side)

Vue.use(ElementUI)

// 页面跳转后滚动条位于最上方
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})



Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')