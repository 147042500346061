<template>
  <footer class="footer">
    <div class="container">
      <el-row :gutter="24" >
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="item">
            <img src="@/assets/img/ft_03.png">
            <div>
              <h6 style="color:#ffd700">联系我们</h6>
              <span>
                <span>电话：400-123-4567</span>
                <span>手机：138 0000 0000</span>
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="item">
            <img src="@/assets/img/ft_05.png">
            <div>
              <h6>公司地址</h6>
              <span>地址：河北省秦皇岛市海港区湘江道8号大医精方总部</span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="item">
            <img src="@/assets/img/ft_07.png">
            <div>
              <h6 >友情链接</h6>
              <span>
                <a style="color:#fff">中医药文化工作委员会</a><br>
                <a style="color:#fff">绿荫工程官方网站</a><br>
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="myFooter">
        <div>公司名称：秦皇岛精方健康管理有限公司版权所有</div>
        备案号：
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">冀ICP备16020099号-1</a>&nbsp;&nbsp;
        <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">冀公网安备13030302000131号</a>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #ac7a4c;
  overflow: hidden;
  .container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0;
      .item{
        display: flex;
        background: #b36f0b;
        padding: 10px;
        margin: 10px;
        img{
          margin-right: 20px;
        }
        h6{
          font-size: 16px;
          color: #fff;
        }
        span{
          font-size: 14px;
          color: #fff;
          display: block;
        }
        a{
          font-size: 14px;
          color: #fff;
        }
      }
  }
  .myFooter{
    padding: 5px;
    text-align:center;
    margin-top:0px;
    bottom:0;
    left:0;
    color:'#666';
    border-top: 1px solid #ccc;
    font-size: 14px;
    color: #fff;
    span{
      margin-right: 20px;
    }
    a{
      color: #fff;
    }
  }
}

.ft1 .ft1_xin .ft1_2 {
	margin: 0 auto;
	margin-left: 5%;
}
.ft1 .ft1_xin .ft1_3 {
	float: right;
}
.ft1_xin ul li {
	width: 26%;
	float: left;
	border: 1px solid #fff;
	padding: 2%;
}
.ft1_xin ul li .ft1_left {
	display: block;
	float: left;
	width: 26%;
	margin: 0 auto;
}
.ft1_xin ul li .ft1_left img {
	width: auto;
	max-width: 90%;
	display: block;
	vertical-align: middle;
}
.ft1_xin ul li .ft1_right {
	font-size: 0.675rem;
	line-height: 1rem;
}
.ft1_xin ul li .ft1_right .ft1_right_font {
	font-size: 1rem;
	line-height: 1.3rem;
}
.ft1_left li {
	float: left;
}
.ft1_left .ft1_img {
	width: 25%;
}
.ft1_left .ft1_font {
	line-height: 1.5rem;
}
</style>