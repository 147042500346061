import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // {path: "/",redirect: "/home"},
  {path: '/',    name: 'Home',   meta: { scrollToTop: true,title:'首页'},component:        () => import('../views/Home.vue')}, 
  {path: '/blurb',   name: 'blurb',  meta: { scrollToTop: true, title:'公司简介', }, component: () => import('../views/blurb.vue') }, 
  {path: '/regimen', name: 'regimen',meta: { scrollToTop: true,title:'养生知识'},component:     () => import('../views/regimen.vue')},
  {path: '/brand',   name: 'brand',  meta: { scrollToTop: true,title:'品牌项目'},component:    () => import('../views/brand.vue')}, 
  {path: '/Gongfa',  name: 'Gongfa', meta: { scrollToTop: true,title:'功法视频' }, component:  () => import('../views/Gongfa.vue')},
  {path: '/honor',  name: 'honor',   meta: { scrollToTop: true,title:'资质荣誉' }, component:  () => import('../views/honor.vue')},
  {path: '/contact', name: 'Contact',meta: { scrollToTop: true,title:'联系我们',},component:   () => import('../views/Contact.vue')}, 
]




const router = new VueRouter({
  // mode: 'hash',
  mode: 'history', // 使用history模式
  base: process.env.BASE_URL,
  routes
})
export default router
